/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import { rhythm } from "../utils/typography"
import Colors from "../constants/Colors";

const Bio = () => {
  return (
    <div style={bioStyle}>
      <p style={bioText}>
        Gode råd der gør det lettere for nybegyndere at investere.
      </p>
    </div>
  )
}

const bioStyle = {
  display: `flex`,
  marginBottom: rhythm(0.6),
}

const bioText = {
  color: Colors.white,
  opacity: 0.8,
}


export default Bio
