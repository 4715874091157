/**
 * Structured data component for
 * article markup - SEO. 
 */

import React from "react"
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

interface ComponentProps {
    articleTitle: string,
    articleDescription: string,
    articleUrl: string,
    datePublished: string,
    thumbnailUrl: string
    language?: string,
    author?: string,
    logoUrl?: string,
}

const StructuredData: React.FunctionComponent<ComponentProps> = ({
    articleTitle,
    articleDescription,
    articleUrl,
    datePublished,
    thumbnailUrl,
    language,
    author,
    logoUrl
}) => {

    const { site } = useStaticQuery(
        graphql`
          query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    siteUrl
                    logoUrl
                    language
                    organization
                }
            }
          }
        `
    )

    const authorString = author || site.siteMetadata.author;
    const organization = site.siteMetadata.organization;
    const logoImageUrl = logoUrl || site.siteMetadata.siteUrl + site.siteMetadata.logoUrl;
    const languageString = language || site.siteMetadata.language;
    const postUrl = site.siteMetadata.siteUrl + articleUrl;
    const thumbnailImageLink = site.siteMetadata.siteUrl + thumbnailUrl;

    const articleSchema = {
        "@context": "http://schema.org",
        "@type": "Article",
        author: {
            "@type": "Person",
            name: authorString,
        },
        copyrightHolder: {
            "@type": "Person",
            name: authorString,
        },
        copyrightYear: new Date().getFullYear().toString(),
        creator: {
            "@type": "Person",
            name: authorString,
        },
        publisher: {
            "@type": "Organization",
            name: organization,
            logo: {
                "@type": "ImageObject",
                url: logoImageUrl,
            },
        },
        datePublished: datePublished,
        dateModified: datePublished,
        description: articleDescription,
        headline: articleTitle,
        inLanguage: languageString,
        url: postUrl,
        name: articleTitle,
        image: {
            "@type": "ImageObject",
            url: thumbnailImageLink,
        },
        mainEntityOfPage: postUrl,
    }

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(articleSchema)}
            </script>
        </Helmet>
    )
}

StructuredData.defaultProps = {
    author: '',
    logoUrl: '',
    language: ''
} as Partial<ComponentProps>;

export default StructuredData;