import React from 'react'
import Colors from '../constants/Colors';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaTwitterSquare } from 'react-icons/fa';
import Variables from '../constants/Variables';

export default class SocialComponent extends React.Component {

    shareOnFacebook = () => {
        let url = 'https://www.facebook.com/sharer/sharer.php?u='+this.props.url;
        window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    }
    
    shareOnTwitter = () => {
        let url = 'https://twitter.com/intent/tweet?url='+this.props.url;
        window.open(url);
    }
    render() {
        return(
            <div className="col-md-6 offset-md-3 text-center">
                <p style={textStyle}>Del siden med andre!</p>
                <ul style={ulstyle}>
                    <li style={liststyle} onClick={() => this.shareOnFacebook()}>
                        <FaFacebookSquare 
                        style={icon}
                        size={35}
                        />
                    </li>
                    <li style={liststyle} onClick={() => this.shareOnTwitter()}>
                        <FaTwitterSquare 
                        style={icon}
                        size={35}
                        />
                    </li>
                </ul>
            </div>
        );
    }
}

const textStyle = {
    color: Colors.grey,
    opacity: 0.8,
    fontFamily: Variables.fontFamilyText,
    fontSize: 18,
    lineHeight: 1,
    marginBottom: 10,
};

const icon = {
    color: Colors.blue,
    margin: 2,
    cursor: 'pointer'
}

const ulstyle = {
    display: "inline",
    listStyleType: "none",
    padding: 10,
    margin : 10
}

const liststyle ={
    float : "left"
}

