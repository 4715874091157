import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Colors from "../constants/Colors"
import commentBox from "commentbox.io"
import ReactGA from "react-ga"
import SocialComponent from "../components/socialcomponent"
import StructuredData from "../components/structuredSEO.tsx"

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    this.removeCommentBox = commentBox("5707624460845056-proj", {
      textColor: Colors.white,
      subtextColor: Colors.grey, // default grey
    })
    ReactGA.pageview(this.props.location.pathname)
    //this.windowpath = String(window.location.href)
  }
  componentWillUnmount() {
    this.removeCommentBox()
  }
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          date={post.frontmatter.date}
        />
        <StructuredData
          articleTitle={post.frontmatter.title}
          articleDescription={post.frontmatter.description}
          articleUrl={"www.nyinvestor.dk" + this.props.location.pathname}
          datePublished={post.frontmatter.date}
        />
        <h1>{post.frontmatter.title}</h1>
        <p style={textStyling}> {post.frontmatter.date} </p>
        <div style={card}>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <div>
            <SocialComponent
              url={"www.nyinvestor.dk" + this.props.location.pathname}
            />
          </div>
          {/*<div style={{verticalAlign:"bottom", marginTop:40, marginLeft:15}}><span style={{fontWeight:"bold"}} >Del </span></div>*/}
        </div>
        <hr style={{ marginBottom: rhythm(1), borderColor: Colors.white }} />
        <Bio />
        <ul style={listStyling}>
          <li>
            {previous && (
              <Link
                className="blog-bottom-link"
                to={previous.fields.slug}
                rel="prev"
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link
                className="blog-bottom-link"
                to={next.fields.slug}
                rel="next"
              >
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

const card = {
  padding: 30,
  paddingBottom: 60,
  borderRadius: 15,
}

const textStyling = {
  display: `block`,
  marginBottom: rhythm(1),
  marginTop: rhythm(-1),
  color: Colors.white,
  opacity: 0.8,
}

const listStyling = {
  display: `flex`,
  flexWrap: `wrap`,
  justifyContent: `space-between`,
  listStyle: `none`,
  padding: 0,
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
