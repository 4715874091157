import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageComponent = () => {
  const data = useStaticQuery(graphql`
  query {
    file(absolutePath: { regex: "/bannerNyinvestorTransparentPOPPINS.png/"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 431) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <div>
      <Img fluid={data.file.childImageSharp.fluid} style={img} />
    </div>
    )
}

const img = {
  paddingRight:'15px',
  maxWidth:'431px'
}

export default ImageComponent;