import Typography from "typography"
import Colors from "../constants/Colors"

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.666,
  bodyColor: Colors.almostBlack,
  headerFontFamily: [
    "Poppins",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  headerWeight: 700,
  headerColor: Colors.white,
  bodyFontFamily: ["Open Sans", "serif"],
})

typography.overrideThemeStyles = () => ({
  h2: {
    color: Colors.almostBlack,
  },
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
